const propertyData = {
    "url": "http://15700NWBarktonSt.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "15700 NW Barkton St", "city": "Beaverton", "state":"Oregon", "zipcode": "97006"},
    "display_address": "15700 NW Barkton St",
    "property": {
      "listing_price": "$686,990",
      "bedrooms": "5",
      "baths": "3",
      "sq_feet": "3382",
      "lot_size": "16117.2",
      "rmls": "20095956",
      "built_in": "1968",
      "blurb": "Come home to coveted Oak Hills! Gorgeous light, bright 5 bdrm/3ba home on huge lot. Open floorplan features kitchen with Shaker style cherry cabinets & granite counters. Master and two bedrooms on main floor. Dining and family room access private landscaped backyard oasis with pond, dog run, 2 patios and covered deck. Everything updated, tons of storage, large shop. Access to new community center w/ pool, tennis courts, acres of greenspace. In the family for 50 yrs, this home is really special.",
      "ammenities": [

      ]
    },
    "image_count": 34,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.6688785635893!2d-122.84203118444009!3d45.53686847910185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f35c09e120f%3A0x88c0b62bae7737f9!2s15700%20NW%20Barkton%20St%2C%20Beaverton%2C%20OR%2097006!5e0!3m2!1sen!2sus!4v1588294570770!5m2!1sen!2sus",
        "youtube":"COIfI9RCh1c"
    }
  }

export default propertyData;

